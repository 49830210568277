import React from "react"
import BusinessVirtualPOS from "../../../components/body/pages/en-ng/business/VirtualPOS"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const VirtualPOS = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/virtual-pos"}
      title="Kuda Virtual POS | Boost Your Business Payments without a Terminal | Kuda Business"
      description="Get a Kuda Virtual POS so you can see all your POS transactions in real-time and receive payments all into a unique bank account for your business."
    />
    <BusinessVirtualPOS />
  </Layout>
)

export default VirtualPOS;
